@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');

.site {
  margin: 1em;
}

.Countdown {
  font-size: xx-large;
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  margin: 0 15%;
}

.Content {
  text-align: center;
  margin: 0 15%;
}

.Home {
  text-align: center;
}



.App-logo {
  height: 35em;
}

.Logo-background {
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
